import React from 'react';
import Index from './components/Index';
import './App.css';

function App() {
  return (
    <>
      <Index />
    </>
  );
}

export default App;
